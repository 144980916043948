import { EVENTS_MAP } from '@components/services/analytics/constants'
import eventDispatcher from '@components/services/analytics/eventDispatcher'
import { recordGA4Event } from '@components/services/analytics/ga4'
import {
  AppsFlyerKeys,
  AppsFlyerParams,
  BETTERCOMMERCE_DEFAULT_CURRENCY,
  EmptyGuid,
  FacebookEvents,
} from '@components/utils/constants'
import {
  LocalStorage,
  PaymentMethodType,
} from '@components/utils/payment-constants'
import {
  getBrowserIdForFBConversion,
  getCategories,
  getCommonAppsFlyerValue,
  getCurrentPage,
  getDateOfBirthHash,
  getGenderHash,
  getIpAddressForFBConversion,
  getKwikPassFields,
  getLineItemSKU,
  getOrderId,
  getOrderInfo,
} from '@framework/utils/app-util'
import { decrypt, getSHA256Hash, getEncryptedNumber } from '@framework/utils/cipher'
import { facebookEventHandler } from 'hooks/facebook-conversion'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'

declare const window: any
declare const AF: any

interface IProps {
  isChannelStore: boolean
  setBasketId: (val: string) => void
  getUserId: () => any
  setCartItems: (val: any) => void
  setOrderId: (val: string) => void
  storeId: string | string[] | null
  dispatch?: any
}

export const beginBasketExpressCheckout = async (
  cartItems: any,
  user: any,
  preferredPaymentMethod: any,
  defaultShippingAddress: any,
  isChannelStore: boolean,
  trackMoEngageEvent: any
) => {
  const gaPayload = {
    ecommerce: {
      items: cartItems?.lineItems?.map((item: any, itemId: number) => ({
        item_name: item?.name,
        item_brand: item?.brand,
        item_category2: getCategories(item).category2,
        item_category: getCategories(item).category,
        item_variant: item?.colorName,
        color: item?.colorName,
        quantity: item?.qty,
        item_id: item?.sku,
        item_size: JSON.parse(item?.attributesJson)?.Size?.toUpperCase() || '',
        price: item?.price?.raw?.withTax,
        item_var_id: item?.stockCode,
        item_list_name: getCategories(item).category,
        index: itemId,
      })),
      current_page: 'Checkout',
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      paymode: preferredPaymentMethod?.method,
      address: defaultShippingAddress,
      value: cartItems?.grandTotal?.raw?.withTax,
      ...getKwikPassFields(),
    },
  }
  recordGA4Event(window, GA_EVENT.BEGIN_CHECKOUT, gaPayload)

  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData: any = {
    data: [
      {
        event_name: FacebookEvents.INITIATE_CHECKOUT,
        event_time: Math.floor(new Date().getTime() / 1000),
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: defaultShippingAddress?.city
            ? await getSHA256Hash(
                defaultShippingAddress?.city?.substring(0, 2)?.toLowerCase()
              )
            : null,
          zp: defaultShippingAddress?.postCode
            ? await getSHA256Hash(defaultShippingAddress?.postCode)
            : null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: cartItems?.lineItems?.length,
          content_name: getCurrentPage(),
          value: cartItems?.grandTotal?.raw?.withTax,
          content_ids: cartItems?.lineItems?.map(
            (item: any) => item?.stockCode
          ),
          content_type: 'product',
          contents: cartItems?.lineItems?.map((item: any) => ({
            id: item?.productId,
            quantity: item?.qty,
            item_price: item?.price?.raw?.withTax,
            delivery_category: isChannelStore ? 'in_store' : 'home_delivery',
          })),
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)

  let couponStatus = false
  if (cartItems?.promotionsApplied?.length > 0) {
    couponStatus = true
  }
  const begin_checkout = {
    URL: window?.location?.href,
    cart_amount: cartItems?.grandTotal?.raw?.withTax || '',
    cart_quantity: cartItems?.lineItems?.length || '',
    coupon_status: couponStatus,
    coupon_label: cartItems?.promotionsApplied[0]?.promoCode || '',
    Product_name: cartItems?.lineItems?.map((item: any) => item?.name || ''),
    Product_ID: cartItems?.lineItems?.map((item: any) => item?.stockCode || ''),
    Price: cartItems?.lineItems?.map(
      (item: any) => item?.price?.raw?.withTax || ''
    ),
    Product_color: cartItems?.lineItems?.map(
      (item: any) => item.colorName || ''
    ),
    Product_category_2: cartItems?.lineItems?.map(
      (item: any) => item?.categoryItems[0]?.categoryName || ''
    ),
    Product_category: cartItems?.lineItems?.map((item: any) =>
      item?.categoryItems.length
        ? item?.categoryItems[item?.categoryItems?.length - 1]?.categoryName
        : ''
    ),
    Quantity: cartItems?.lineItems?.map((item: any) => item?.qty || ''),
    Product_size: cartItems?.lineItems?.map(
      (item: any) =>
        item?.variantProducts
          ?.find(
            (variantProductDatum: any) =>
              variantProductDatum?.stockCode === item?.stockCode
          )
          ?.variantAttributes?.find(
            (variantAttributeDatum: any) =>
              variantAttributeDatum?.fieldCode === 'clothing.size'
          )?.fieldValue || ''
    ),
  }
  trackMoEngageEvent(MO_ENGAGE_EVENT.BEGIN_CHECKOUT, begin_checkout, window)
}

export const handlePurchaseInitialtedAfterFailureGAEvent = (
  user: any,
  str: string
) => {
  if (str === 'UPI') {
    return recordGA4Event(window, GA_EVENT.PURCHASE_INITIATED_AFTER_FAILURE, {
      paymode_info: PaymentMethodType.UPI,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  } else if (str === 'WALLET') {
    return recordGA4Event(window, GA_EVENT.PURCHASE_INITIATED_AFTER_FAILURE, {
      paymode_info: PaymentMethodType.WALLET,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  } else if (str === 'NETBANKING') {
    return recordGA4Event(window, GA_EVENT.PURCHASE_INITIATED_AFTER_FAILURE, {
      paymode_info: PaymentMethodType.NETBANKING,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  } else if (str === 'COD') {
    return recordGA4Event(window, GA_EVENT.PURCHASE_INITIATED_AFTER_FAILURE, {
      paymode_info: PaymentMethodType.COD,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  } else if (str === 'CARD') {
    return recordGA4Event(window, GA_EVENT.PURCHASE_INITIATED_AFTER_FAILURE, {
      paymode_info: PaymentMethodType.CARD,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  } else if (str === 'DIFFERENT') {
    return recordGA4Event(window, GA_EVENT.PURCHASE_INITIATED_AFTER_FAILURE, {
      paymode_info: PaymentMethodType.DIFFERENT_PAY_MODE,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    })
  }
}

export const handlePurchaseGAEvent = async (
  payment: any,
  cartItems: any,
  user: any,
  isGoKwikUser: boolean,
  addressesList: Array<any>
) => {
  const userMobile =
    user?.mobile || user?.telephone || user?.phone || user?.username
  const mobileRSA = getEncryptedNumber(userMobile)
  const gaPayload = {
    ecommerce: {
      transaction_id: payment ? `${payment?.orderNo}-${payment?.id}` : '',
      affiliation: 'Online Store',
      value: payment?.orderAmount || '',
      shipping: cartItems?.shippingCharge?.raw?.withTax ?? 0,
      currency: cartItems?.currencyCode || BETTERCOMMERCE_DEFAULT_CURRENCY,
      loggedIn: true,
      coupon: cartItems?.promotionsApplied?.length
        ? cartItems?.promotionsApplied?.map((x: any) => x?.promoCode)?.join(',')
        : '',
      items: cartItems?.lineItems?.map((item: any) => ({
        item_name: item?.name,
        price: item?.price?.raw?.withTax,
        quantity: item?.qty,
        item_id: getLineItemSKU(item?.attributesJson),
        item_size: item?.variantProducts
          ?.find((x: any) => x?.stockCode === item?.stockCode)
          ?.variantAttributes?.find(
            (x: any) => x?.fieldCode === 'clothing.size'
          )?.fieldValue,
        item_var_id: item?.stockCode,
        item_variant: item.colorName,
      })),
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      address_source: isGoKwikUser ? 'kwikpass' : 'Damensch',
      mobile_number: mobileRSA,
      ...getKwikPassFields(),
    },
    userData: {
      email: user?.email ? await getSHA256Hash(user?.email) : '',
      phone: userMobile ? await getSHA256Hash(userMobile) : '',
    },
  }
  return recordGA4Event(window, GA_EVENT.PURCHASE, gaPayload)
}

export const handleFBEvent = async (
  user: any,
  defaultShippingAddress: any,
  cartItems: any,
  payment: any,
  isChannelStore: boolean
) => {
  const mobileNumber = user?.mobile || user?.telephone
  let facebookeventData: any = {
    data: [
      {
        event_name: FacebookEvents.PURCHASE,
        event_time: Math.floor(new Date().getTime() / 1000),
        action_source: 'website',
        user_data: {
          em: [user?.email ? await getSHA256Hash(user?.email) : ''],
          ph: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          fn: user?.firstName ? await getSHA256Hash(user?.firstName) : null,
          ln: user?.lastName ? await getSHA256Hash(user?.lastName) : null,
          client_user_agent: window.navigator.userAgent,
          fbp: getBrowserIdForFBConversion(),
          client_ip_address: (await getIpAddressForFBConversion()) || null,
          external_id: mobileNumber ? await getSHA256Hash(mobileNumber) : null,
          db: await getDateOfBirthHash(user),
          ge: await getGenderHash(user?.gender),
          ct: defaultShippingAddress?.city
            ? await getSHA256Hash(
                defaultShippingAddress?.city?.substring(0, 2)?.toLowerCase()
              )
            : null,
          zp: defaultShippingAddress?.postCode
            ? await getSHA256Hash(defaultShippingAddress?.postCode)
            : null,
          country: await getSHA256Hash('in'),
        },
        custom_data: {
          currency: 'INR',
          num_items: cartItems?.lineItems?.length,
          content_name: getCurrentPage(),
          value: payment?.orderAmount,
          content_ids: cartItems?.lineItems?.map(
            (item: any) => item?.stockCode
          ),
          content_type: 'product',
          order_id: payment ? `${payment?.orderNo}-${payment?.id}` : '',
          contents: cartItems?.lineItems?.map((item: any) => ({
            id: item?.productId,
            quantity: item?.qty,
            item_price: item?.price?.raw?.withTax,
            delivery_category: isChannelStore ? 'in_store' : 'home_delivery',
          })),
        },
      },
    ],
  }

  facebookEventHandler(facebookeventData)
}

export const handleAppsFlyerEvents = async (
  cartItems: any,
  payment: any,
  data: any,
  guidOrderId: any,
  user: any,
  dispatch: any,
  trackMoEngageEvent: any,
  checkoutCallback: (val: any, data: IProps) => void,
  _data: IProps
) => {
  const {
    isChannelStore,
    setBasketId,
    getUserId,
    setCartItems,
    setOrderId,
    storeId,
  } = _data
  const { CheckoutConfirmation } = EVENTS_MAP.EVENT_TYPES
  const { Order } = EVENTS_MAP.ENTITY_TYPES
  const {
    basketId,
    customerId,
    billingAddress,
    discount,
    grandTotal,
    id,
    items,
    orderNo,
    paidAmount,
    payments,
    promotionsApplied,
    shippingCharge,
    shippingAddress,
    shipping,
    orderStatus,
    subTotal,
    taxPercent,
    orderDate,
  } = data
  const currentPage = getCurrentPage()
  const commonEventValue = getCommonAppsFlyerValue()
  const orderInfo = await getOrderInfo()
  const decryptedOrderModal = decrypt(
    localStorage?.getItem(LocalStorage.Key.ORDER_PAYMENT) as string
  )
  const orderModel = JSON.parse(decryptedOrderModal)
  const txnOrderId = getOrderId(orderModel)
  if (typeof AF !== 'undefined') {
    AF('pba', 'event', {
      eventType: 'EVENT',
      eventName: AppsFlyerKeys.Purchase,
      eventRevenueCurrency: 'INR',
      eventValue: {
        [AppsFlyerParams.AFEventParam3]: currentPage,
        [AppsFlyerParams.AFEventParamCouponCode]: cartItems?.promotionsApplied
          ?.length
          ? cartItems?.promotionsApplied
              ?.map((x: any) => x?.promoCode)
              ?.join(',')
          : '',
        [AppsFlyerParams.AFEventParamRevenue]: payment?.orderAmount || '',
        [AppsFlyerParams.AFEventParamOrderId]: payment?.id,
        [AppsFlyerParams.AFEventParamCustomInfo]: cartItems?.lineItems?.map(
          (item: any) => ({
            item_name: item?.name,
            price: item?.price?.raw?.withTax,
            quantity: item?.qty,
            item_id: getLineItemSKU(item?.attributesJson),
            item_size: item?.variantProducts
              ?.find((x: any) => x?.stockCode === item?.stockCode)
              ?.variantAttributes?.find(
                (x: any) => x?.fieldCode === 'clothing.size'
              )?.fieldValue,
            item_brand: item?.brand,
            item_var_id: item?.stockCode,
            item_variant: item.colorName,
          })
        ),
        ...commonEventValue,
      },
    })
  }

  eventDispatcher(CheckoutConfirmation, {
    basketItemCount: items.length,
    basketTotal: grandTotal?.raw?.withTax,
    shippingCost: shippingCharge?.raw?.withTax,
    promoCodes: promotionsApplied,
    basketItems: JSON.stringify(
      items.map((i: any) => {
        return {
          categories: i.categoryItems,
          discountAmt: i.discountAmt?.raw?.withTax,
          id: i.id,
          img: i.image,
          isSubscription: i.isSubscription,
          itemType: i.itemType,
          manufacturer: i.manufacturer,
          name: i.name,
          price: i.price?.raw?.withTax,
          productId: i.productId,
          qty: i.qty,
          rootManufacturer: i.rootManufacturer || '',
          stockCode: i?.stockCode,
          subManufacturer: i?.subManufacturer,
          tax: i?.totalPrice?.raw?.withTax,
        }
      })
    ),
    entity: JSON.stringify({
      basketId: basketId,
      billingAddress: billingAddress,
      customerId: customerId,
      discount: discount?.raw?.withTax,
      grandTotal: grandTotal?.raw?.withTax,
      id: id,
      lineitems: items,
      orderNo: orderNo,
      paidAmount: paidAmount?.raw?.withTax,
      payments: payments.map((i: any) => {
        return {
          methodName: i.paymentMethod,
          paymentGateway: i.paymentGateway,
          amount: i.paidAmount,
        }
      }),
      promoCode: promotionsApplied,
      shipCharge: shippingCharge?.raw?.withTax,
      shippingAddress: shippingAddress,
      shippingMethod: shipping,
      status: orderStatus,
      subTotal: subTotal?.raw?.withTax,
      tax: grandTotal?.raw?.withTax,
      taxPercent: taxPercent,
      timestamp: orderDate,
    }),
    entityId: guidOrderId, //orderModelResponse.id,
    entityName: orderNo,
    entityType: Order,
    eventType: CheckoutConfirmation,
  })
  dispatch({ type: 'TRIGGER_COD', payload: false })

  if (typeof window !== 'undefined') {
    const purchase_event_obj = {
      transaction_id: payment?.id
        ? `${payment?.orderNo}-${payment?.id}`
        : `${payment?.orderNo}-`,
      mobile_number:
        user?.mobile || user?.telephone || user?.phone || user?.username,
      Purchase_Amount:
        orderInfo?.jusPayOrder?.effective_amount ||
        orderInfo?.order?.orderAmount,
      Cart_Amount:
        cartItems?.grandTotal?.raw?.withTax || orderInfo?.order?.orderAmount,
      Product_name: cartItems?.lineItems?.map((item: any) => item?.name || ''),
      Product_ID: cartItems?.lineItems?.map(
        (item: any) => item?.stockCode || ''
      ),
      Price: cartItems?.lineItems?.map(
        (item: any) => item?.price?.raw?.withTax || ''
      ),
      Product_color: cartItems?.lineItems?.map(
        (item: any) => item?.colorName || ''
      ),
      Product_category: cartItems?.lineItems?.map((item: any) =>
        item?.categoryItems?.length
          ? item?.categoryItems[item?.categoryItems.length - 1]?.categoryName
          : ''
      ),
      Product_category_2: cartItems?.lineItems?.map(
        (item: any) => item?.categoryItems[0]?.categoryName || ''
      ),
      Quantity: cartItems?.lineItems?.map((item: any) => item?.qty || ''),
      Product_size: cartItems?.lineItems?.map(
        (item: any) =>
          item?.variantProducts
            ?.find((x: any) => x?.stockCode === item?.stockCode)
            ?.variantAttributes?.find(
              (x: any) => x?.fieldCode === 'clothing.size'
            )?.fieldValue || ''
      ),
      Online_transaction: !isChannelStore ? true : false,
      PPG_transaction: isChannelStore ? true : false,
    }
    trackMoEngageEvent(MO_ENGAGE_EVENT.PURCHASE, purchase_event_obj, window)
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.PURCHASE_SUMMARY,
      {
        Purchase_Amount:
          cartItems?.grandTotal?.raw?.withTax || orderInfo?.order?.orderAmount,
        Cart_Amount:
          cartItems?.grandTotal?.raw?.withTax || orderInfo?.order?.orderAmount,
        Total_items: cartItems?.lineItems?.length,
        Order_id: txnOrderId,
        Discount: cartItems?.discount?.raw?.withTax,
        coupon_status: cartItems?.promotionsApplied?.length ? true : false,
        coupon_label: cartItems?.promotionsApplied?.length
          ? cartItems?.promotionsApplied
              ?.map((x: any) => x?.promoCode)
              ?.join(',')
          : '',
      },
      window
    )
  }

  checkoutCallback(guidOrderId, {
    isChannelStore,
    setBasketId,
    getUserId,
    setCartItems,
    setOrderId,
    storeId,
    dispatch,
  })
}
