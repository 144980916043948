import RelatedProducts from '@components/product/RelatedProducts'
import {
  BAG_EMPTY_MESSAGE,
  BAG_EMPTY_TEXT,
  BTN_START_SHOPPING,
  CART_TEXT,
} from '@components/utils/textVariables'
import Link from 'next/link'
import { handleClose } from './helper'
import { useUI } from '@components/ui'
import { IDeviceInfo } from '@components/ui/context'
import { FREQUENTLY_BOUGHT_TOGETHER_BTM_BTN, IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

interface IEmptyCart {
  altRelatedProducts: any
  deviceInfo: IDeviceInfo
  wishlistLoader: boolean
  currentPage: string
  previousPath: string
  setLoader: any
}

const EmptyCart = (props: IEmptyCart) => {
  const {
    altRelatedProducts,
    deviceInfo,
    wishlistLoader,
    currentPage,
    previousPath,
    setLoader,
  } = props
  const { closeSidebar } = useUI()

  return (
    <>
      <div className="flex flex-col w-full h-full p-8 text-gray-900 bg-gray-100">
        <div className="flex flex-col">
          <img
            alt="test"
            src={`${IMAGE_ASSET_BASE_URL}/icons/empty-bag.svg`}
            width={100}
            height={128}
          />
          <h3 className="mt-4 mb-2 text-lg font-bold text-black dark:text-black">
            {BAG_EMPTY_MESSAGE}
          </h3>
          <p className="text-sm font-normal text-gray-500 dark:text-gray-500">
            {BAG_EMPTY_TEXT}
          </p>
        </div>
        <div className="flex flex-col w-full mt-4 text-center">
          <Link legacyBehavior href="/men/all" passHref>
            <a
              onClick={() =>
                handleClose(closeSidebar, currentPage, previousPath)
              }
              className="w-full py-3 font-semibold text-white bg-black rounded text-md hover:bg-gray-800"
            >
              {BTN_START_SHOPPING}
            </a>
          </Link>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="section-devider-sm" />
      </div>
      <div className="flex flex-col pb-10 mt-0 sm:pb-16 cart-related-prod ">
        {altRelatedProducts?.relatedProducts?.products?.results?.length && (
          <RelatedProducts
            relatedProducts={
              altRelatedProducts?.relatedProducts?.products?.results
            }
            productPerColumn={1.8}
            title="You May Also Like"
            deviceInfo={deviceInfo}
            pageType={CART_TEXT}
            wishlistLoader={wishlistLoader}
            setLoader={setLoader}
            showBtmBtn={FREQUENTLY_BOUGHT_TOGETHER_BTM_BTN}
          />
        )}
      </div>
    </>
  )
}

export default EmptyCart
