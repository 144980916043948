import Link from 'next/link'
import { FC } from 'react'
import axios from '@services/axiosInterceptorInstance'
import { useUI } from '@components/ui/context'
import { useEffect, Fragment, useState } from 'react'
import cartHandler from '@components/services/cart'
import { Dialog, Transition } from '@headlessui/react'
import useWishlist from '@components/services/wishlist'
import { generateUri } from '@commerce/utils/uri-util'
import { ArrowLeft, CheckCircle, Cross } from '@components/icons'
import Spinner from '@components/ui/Spinner'
import {
  NEXT_REMOVE_WISHLIST,
  NEXT_GET_WISHLIST,
  EmptyGuid,
} from '@components/utils/constants'
import {
  WISHLIST_TITLE,
  GENERAL_ADD_TO_BASKET,
  WISHLIST_SUCCESS_MESSAGE,
  GENERAL_CONTINUE_SHOPPING,
  PROD_ADDED_SUCCESSFULLY,
  INSUFFICIENT_STOCK,
  PROD_ADDED,
} from '@components/utils/textVariables'
import { isCartAssociated, openNewCartPage } from '@framework/utils/app-util'
import { matchStrings } from '@framework/utils/parse-util'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

const WishlistSidebar: FC = () => {
  const {
    closeSidebar,
    setWishlist,
    wishListItems,
    user,
    wishlistItems,
    basketId,
    cartItems,
    setCartItems,
    removeFromWishlist,
    openCart,
    setAlert,
  } = useUI()
  const { getWishlist, deleteWishlistItem } = useWishlist()

  const [isItemInCart, setItemInCart] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleWishlistItems = async () => {
    const items = await getWishlist(user.userId, wishlistItems)
    setWishlist(items)
  }
  let accessToken: boolean | any = false

  if (typeof window !== 'undefined') {
    accessToken = localStorage?.getItem('user')
  }

  useEffect(() => {
    if (accessToken) handleWishlistItems()
  }, [])

  const deleteItemFromWishlist = (productId: string) => {
    if (accessToken) {
      deleteWishlistItem(user.userId, productId).then(() =>
        handleWishlistItems()
      )
    } else removeFromWishlist(productId)
  }

  const handleAddToCart = (product: any) => {
    cartHandler()
      .addToCart(
        {
          basketId,
          productId: product.recordId,
          qty: 1,
          // manualUnitPrice: product.price.raw.withTax,
          stockCode: product.stockCode,
          userId: user.userId,
          isAssociated: isCartAssociated(cartItems), //user.isAssociated,
        },
        'ADD',
        { product }
      )
      .then((response: any) => {
        // setCartItems(response)
        if (
          response?.message &&
          !matchStrings(response?.message, PROD_ADDED_SUCCESSFULLY, true)
        ) {
          setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        } else if (response?.id === EmptyGuid) {
          setAlert({ type: 'error', msg: INSUFFICIENT_STOCK })
        } else {
          setAlert({ type: 'success', msg: PROD_ADDED })
          setCartItems(response)
        }

        setItemInCart(true)
        setTimeout(() => {
          setItemInCart(false)
        }, 3000)
        handleRemoveFromWishlist(product)
      })
      .then(() => {
        // openCart()
        openNewCartPage(openCart)
      })
      .catch((err: any) => console.error(':: error', err))
  }

  const handleRemoveFromWishlist = (product: any) => {
    const handleAsync = async () => {
      try {
        await axios.post(NEXT_REMOVE_WISHLIST, {
          id: user?.userId,
          productId: product?.recordId,
          flag: true,
        })
        fetchItems()
      } catch (error) {
        console.error(':: error', error)
      }
    }
    handleAsync()
  }

  const fetchItems = async () => {
    !isLoading && setIsLoading(true)
    try {
      const response: any = await axios.post(NEXT_GET_WISHLIST, {
        id: user.userId,
        flag: true,
      })
      setIsLoading(false)
      setWishlist(response.data)
    } catch (error) {
      console.error(':: error', error)
      setIsLoading(false)
    }
  }

  const handleClose = () => closeSidebar()

  const isEmpty: boolean = wishListItems?.length === 0

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={handleClose}
      >
        <div className="">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className="absolute inset-0 z-50 overflow-hidden bg-black/[.5]"
              onClick={handleClose}
            />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-10 z-999">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-400"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-400"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="flex flex-col h-full overflow-x-hidden overflow-y-auto bg-white shadow-xl custom-scroll">
                  <div className="sticky top-0 z-50 flex flex-col w-full pt-5 bg-white border-b border-gray-200">
                    <div className="flex items-start justify-start px-4 pb-3 border-b sm:px-8">
                      <Dialog.Title className="text-lg font-medium text-gray-900 dark:text-black">
                        <button
                          type="button"
                          className="p-0 mr-5 text-black hover:text-black"
                          onClick={handleClose}
                        >
                          <Cross
                            className="hidden w-6 h-6 sm:block"
                            aria-hidden="true"
                          />
                          <ArrowLeft
                            className="block w-6 h-6 sm:hidden"
                            aria-hidden="true"
                          />
                        </button>
                      </Dialog.Title>
                      <div className="relative -top-1">
                        <span className="text-xl font-bold dark:text-black">
                          {' '}
                          {WISHLIST_TITLE}
                        </span>
                        <span className="pl-2 text-sm font-normal dark:text-black">
                          {wishListItems?.length}
                          {wishListItems?.length > 1 ? ' items' : ' item'}
                        </span>
                      </div>
                    </div>
                    <div className="mt-8">
                      <div className="flow-root">
                        {isEmpty && (
                          <div className="flex flex-col items-start justify-center w-full px-4 py-12 sm:items-center max-acc-container sm:px-0">
                            <img
                              placeholder="blur"
                              src={`${IMAGE_ASSET_BASE_URL}/icons/basket-no-item.svg`}
                              alt="no basket icon"
                              className="m-92-img"
                              width={92}
                              height={96}
                            />
                            <div className="my-0 font-semibold text-secondary-full-opacity text-m-16 text-24">
                              You haven’t wished for anything yet
                            </div>
                            <p className="text-xs sm:text-sm text-primary opacity-60">
                              Explore more and save items in your wishlist.{' '}
                            </p>
                            <div className="flex w-full mt-5 sm:flex-col">
                              <button
                                type="button"
                                className="w-full flex items-center justify-center px-4 py-3 -mr-0.5 text-white bg-black border-2 border-black rounded-sm hover:bg-gray-800 hover:text-whitesm:px-6 hover:border-gray-900"
                                onClick={handleClose}
                              >
                                Start Shopping
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="flex-1 px-4 pt-0 sm:px-0">
                          <div className="px-0 sm:px-8">
                            <div className="flow-root">
                              {isLoading ? (
                                <Spinner />
                              ) : (
                                <ul
                                  role="list"
                                  className="-my-6 divide-y divide-gray-200"
                                >
                                  {wishListItems?.map((product: any) => (
                                    <li key={product.id} className="">
                                      <div className="flex w-full gap-3 py-6 sm:gap-6">
                                        <div className="flex-shrink-0">
                                          <img
                                            width={72}
                                            height={128}
                                            src={`${generateUri(
                                              product.image,
                                              'w=400&q=100'
                                            )}`}
                                            alt={product.name}
                                            className="basket-image"
                                          />
                                        </div>
                                        <div className="flex flex-col flex-1">
                                          <div>
                                            <div className="flex flex-col justify-between font-medium text-gray-900">
                                              <p className="font-normal text-10 text-brown-light">
                                                {
                                                  product?.classification
                                                    ?.category
                                                }
                                              </p>
                                              <h3 className="pr-6 mb-4 font-normal truncate text-12 text-primary dark:text-black text-ellipsis w-72">
                                                <Link
                                                  legacyBehavior
                                                  href={`/${product.slug}`}
                                                >
                                                  {product.name}
                                                </Link>
                                              </h3>
                                              <p className="font-semibold text-black text-12 sm:text-md">
                                                {
                                                  product.price?.formatted
                                                    ?.withTax
                                                }
                                              </p>
                                              <div className="flex justify-between w-full py-2">
                                                <button
                                                  type="button"
                                                  className="text-orange"
                                                  onClick={() =>
                                                    handleAddToCart(product)
                                                  }
                                                >
                                                  {GENERAL_ADD_TO_BASKET}
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="relative content-start right-12 sm:right-6">
                                          <button
                                            type="button"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                            onClick={() =>
                                              deleteItemFromWishlist(
                                                product.recordId
                                              )
                                            }
                                          >
                                            <span className="sprite-icon trash-icon"></span>
                                          </button>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="px-4 py-2 border-t border-gray-200 sm:px-6">
                    {isItemInCart && (
                      <div className="items-center justify-center w-full h-full py-5 text-xl text-gray-500">
                        <CheckCircle className="flex items-center justify-center w-full h-12 text-center text-black" />
                        <p className="mt-5 text-center">
                          {WISHLIST_SUCCESS_MESSAGE}
                        </p>
                      </div>
                    )}
                    {!isEmpty && (
                      <div className="flex justify-center mt-6 text-sm text-center text-gray-500">
                        <button
                          type="button"
                          className="w-full text-white bg-black rounded btn-basic-property hover:bg-gray-800"
                          onClick={handleClose}
                        >
                          {GENERAL_CONTINUE_SHOPPING}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default WishlistSidebar
